<template>
  <div v-if="isShow">
    <Loading/>
  </div>
  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("about") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/about">
            {{ $t("about") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="minHeight middle common-padding-100">
      <div class="about" :style="{ 'background-image': 'url(' + rightImg + ')' }" >
        <div class="about-left">
          <div class="about-des">
            <h1>{{ info.title }}</h1>
            <Editor :content="info.intro"></Editor>
            <div class="about-02">
              <div class="about-02-txt">
                <h2>{{ info.label_content_one }}</h2>
                <p style="color: #000">{{ info.label_one }}</p>
              </div>
              <div class="about-02-txt">
                <h2>{{ info.label_content_two }}</h2>
                <p style="color: #000">{{ info.label_two }}</p>
              </div>
            </div>
            <div class="about-list">
              <ul>
                <li>
                  <img src="@/assets/img/logo.png">
                  <h3>{{info.content_one}}</h3>
                </li>
                <li>
                  <img src="@/assets/img/logo.png">
                  <h3>{{info.content_two}}</h3>
                </li>
                <li>
                  <img src="@/assets/img/logo.png">
                  <h3>{{info.content_three}}</h3>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
  </div>
  <FooterInquiry></FooterInquiry>
</template>

<script>

import Editor from "@/components/Editor";
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import {aboutInfo} from "@/network/main";

export default {
  name: "About",
  components:{
    Editor,
    FooterInquiry,
    Loading
  },
  data(){
    return{
      isShow:true,
      banner:'',
      seo:'',
      info:'',
      rightImg:'',
    }
  },
  metaInfo () {
    return {
      title: "About Us - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      aboutInfo().then(res =>{
        this.isShow = false;
        this.banner = res.data.data.banner;
        this.info = res.data.data.info;
        this.rightImg = res.data.data.info.image;
        this.seo = res.data.data.seo;
        // 手动更新标题和 meta 信息
        document.title = "About Us - OSONG ELECTRIC Co. LTD";
      })
    },
  }
}
</script>


<style scoped>
.editor h1{
  padding-bottom:15px;
  font-size: 48px;
}
.editor p{
  padding:5px 0;
 font-size: 18px;
}
.editor img{
  padding: 10px 0;
}

@media only screen and (max-width: 992px) {
  .editor h1{
    font-size: 24px;
    padding-bottom:10px;
  }
}

</style>